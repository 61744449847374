import React from "react";

function Footer() {
  return (
    <div className="Footer" id="area">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src="Assets/footer-logo.png" alt="" />
          </div>
          <div className="footer-items">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 footer-services">
                  <h3>Services</h3>
                  <ul><li><a href="/">Appliance Repair</a></li>
                  <li><a href="/">Home Repair</a></li>
                  <li><a href="/">Remodeling</a></li>
                  <li><a href="/">Painting</a>
                  </li>
                  <li><a href="/">Electrical</a></li>
                  <li><a href="/">Plumbing</a></li>
                  <li><a href="/">Power Washing</a></li>
                  </ul>
                  </div>
                  <div className="col-md-6 footer-time">
                  <h3>Hour Of Operation</h3>
                  <ul><li><a href="/">Monday: 9:00 AM – 5:00 PM</a></li>
                  <li><a href="/">Tuesday: 9:00 AM – 5:00 PM</a></li>
                  <li><a href="/">Wednesday: 9:00 AM – 5:00 PM
                  </a></li>
                  <li><a href="/">Thursday: 9:00 AM – 5:00 PM
                  </a>
                  </li>
                  <li><a href="/">Friday: 9:00 AM – 5:00 PM</a></li>
                  </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 services-area">
                <div className="row">
                  <div className="col-md-6">  <h3>Services Area</h3>
                  <ul><li><a href="/">Virginia
                  </a></li>
                  <li><a href="/">Washington DC</a></li>
                  <li><a href="/">Maryland</a></li>
                  </ul></div>
                  <div className="col-md-6 contact-us">  <h3>Contact Us</h3>
                  <ul><li><a href="/"><img src="Assets/download (1).svg" alt="" />+1 571-330-1544</a></li>
                  <li ><a href="/"  className="contact-email"><img src="Assets/download (2).svg" alt="" /><p> MPHOMEIMPROVEMENT SERVICES@GMAIL.COM</p></a></li>
                  <li><a href="/" className="contact-email"><img src="Assets/download (3).svg" alt="" /><p> 100 Sumerduck Rd, Remington, VA, 22734,<br /> USA
                  8622 ARTILLERY RD, Manassas, VA, 20110, USA</p></a></li>
                  </ul></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
