import React from 'react'

function About() {
  return (
    <div className='About'>
    <div className="container">
    <h2 className='about-heading'>About Us</h2>
    <div className="row">
    <div className="col-md-6 about-left">
    <h1>We’re Delivering The Best Customer Experience</h1>
    <div>
        <div className='mission'><img src="Assets/svg1.svg" alt="" />
        <div><h3>Our Mission</h3>
        <p>We're dedicated to transforming houses into homes through top-quality home improvement services that exceed expectations</p>
        </div>
        </div>
        <div className='vision'><img src="Assets/svg2.svg" alt="" />
        <div><h3>Our Vision</h3>
        <p>Our vision is to be the go-to destination for homeowners seeking reliable, affordable, and innovative home improvement solutions, making every house a dream home.
        </p>
        </div>
        </div>
    </div>
    </div>
    <div className='col-md-6 about-right'>
    <div className='about-img'>
    <img src="Assets/worker.png" alt="" /></div></div>
    </div>
    </div>

    </div>
  )
}

export default About