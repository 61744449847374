import React from 'react'

function Hero() {
  return (
    <div className='Hero'  id='home'>
    <div className="container">
    <div className="row">
    <div className="col-md-6 hero-heading"><h1>Welcome to MP Home Improvement Services
    </h1>
    <p className='hero-text'>Welcome to MP Home Improvement Services, your one-stop solution for all your home repair, appliance repair, remodeling, painting, electrical, plumbing, and power washing needs in Virginia. We are dedicated to providing high-quality services and exceptional customer satisfaction.</p>
    <button className='button'>Explore More</button>
    </div>
    <div className="col-md-6 hero-img">
    <img className='' src="Assets/hero.png" alt="" /></div>
    </div>
    </div></div>
  )
}

export default Hero