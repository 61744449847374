import React from "react";

function Contact() {
  return (
    <div className="Contact" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 contact-left">
            <h1>Contact Us</h1>
            <p>
              Ready to schedule an appointment or have questions about our
              services? Don't hesitate to get in touch with us today. You can
              reach us by phone at [insert phone number] or email us at [insert
              email address]. Our friendly and knowledgeable staff are standing
              by to assist you.
            </p>
          </div>
          <div className="col-md-6 contact-right">
            <div>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="name"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    aria-label="subject"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="col email">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                  aria-label=""
                />
              </div>
              <div className="col message">
                <textarea
                  type="message"
                  className="form-control"
                  placeholder="Leave a comment here"
                  aria-label=""
                />
              </div>
              <div className="send-message">
              <button className="send">Send</button>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
