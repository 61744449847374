import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './Components/Layout/Header';
import Hero from './Components/Element/Hero';
import About from './Components/Element/About';
import Service from './Components/Element/Service'
import Work from './Components/Element/Work';
import Contact from './Components/Element/Contact';
import Footer from './Components/Layout/Footer';

function App() {
  return (
    <div>
    <Header/>
    <Hero/>
    <About/>
    <Service/>
    <Work/>
    <Contact/>
    <Footer/>
    </div>
  );
}

export default App;
