import React from "react";

function Work() {
  return (
    <div className="Work" id="work">
      <div className="container">
        <div className="work-heading">
          <h1>Work With</h1>
          <p>
            We believe in the power of collaboration, and our success is deeply <br />
            rooted in the strong relationships we build with our vendors.
          </p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img src="Assets/dacor.jpeg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">DACOR</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img
                src="Assets/long.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <p className="card-text">2-10 HOME BUYERS WARRANTY</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img
                src="Assets/choice.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <p className="card-text">CHOICE HOME WARRANTY</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img
                src="Assets/samsung.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <p className="card-text">Samsung</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card depot-card" style={{ width: "18rem" }}>
              <img
                src="Assets/depot.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <p className="card-text">The Home Depot</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img
                src="Assets/point.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <p className="card-text">Home Warranty Solutions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
